.main {
    display: flex;
    flex-direction: column;

    h3 {
        font-size: 24px;
        font-family: "Suisse-Medium";
        font-weight: normal;
        color: #1d1d20;
        text-align: center;
        max-width: 35%;
        line-height: 1.26;
        width: 600px;
        max-width: 90%;

        @media only screen and (min-width: 900px) {
            font-size: 30px;
        }

        @media only screen and (min-width: 1200px) {
            font-size: 38px;
        }
    }

    .header-description {
        font-size: 18px;
        max-width: 600px;
        line-height: 1.5;
        text-align: center;
        padding: 0 1.5rem;
    }

    .rect-2 {
        position: absolute;
        bottom: 0;
        z-index: 3;
        width: 100%;
        height: 80px;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
    }

    .background-container {
        pointer-events: none;
    }

    .section-1 {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2%;
        width: 100vw;
        height: calc(100vh - 65px);
        height: calc(100svh - 65px);
        max-width: 100%;
        overflow: hidden;

        @media only screen and (min-width: 900px) {
            height: calc(100vh - 116px);
            height: calc(100svh - 116px);
        }

        .section-1-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 2;
            height: 100%;

            .section-1-content {
                padding: 32px 0 40px 0;
                width: 100%;
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: center;

                .section-1-text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    justify-content: space-between;
                    max-height: 450px;

                    h5 {
                        max-width: 90%;

                        @media only screen and (min-width: 600px) {
                            width: 600px;
                        }
                    }

                    .button-row {
                        button + button {
                            margin-left: 1rem;
                            background: white;
                            color: black;
                        }
                    }
                }
            }
        }

        h1 {
            font-size: 40px;
            font-family: "Suisse-SemiBold", "Roboto";
            font-weight: normal;
            text-align: center;
            max-width: 90%;
            z-index: 2;
            margin: 0;

            br {
                display: none;
            }

            @media (orientation: portrait) and (min-height: 1000px) {}

            @media only screen and (min-width: 600px) {
                font-size: 48px;
            }

            @media only screen and (min-width: 900px) {
                font-size: 55px;

                br {
                    display: block;
                }
            }

            @media only screen and (min-width: 1200px) and (min-height: 950px) {
                font-size: 76px;
            }

            @media only screen and (min-width: 1800px) and (min-height: 950px) {
                font-size: 82px;
            }
        }

        h5 {
            color: #1d1d20;
            font-family: "Inter-Medium", "Roboto";
            font-weight: normal;
            font-size: 16px;
            text-align: center;
            line-height: 1.6;
            z-index: 2;
            margin: 0;

            @media (orientation: portrait) and (min-height: 1000px) {}

            @media only screen and (min-width: 900px) {
                font-size: 17px;
                width: 55%;
            }

            @media only screen and (min-width: 1200px) and (min-height: 950px) {
                line-height: 1.8;
                max-width: 600px;
            }

            @media only screen and (min-width: 1800px) and (min-height: 950px) {
                font-size: 20px;
            }
        }

        button {
            font-size: 14px;
            font-family: "Suisse-Medium", "Roboto";
            width: 170px;
            height: 40px;
            padding: 10px 28px;
            border-radius: 24px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
            color: white;
            border: solid 1px #404040;
            background-image: linear-gradient(to bottom, #1d1d20, #232326);
            z-index: 5;
            cursor: pointer;

            @media (orientation: portrait) and (min-height: 1000px) {
                margin: 55px 0 0 0;
            }

            @media only screen and (min-height: 900px) {
                font-size: 16px;
                width: 200px;
                height: 48px;
            }
        }

        .background {
            position: absolute;
            object-fit: fill;
            width: 100%;
            bottom: 0;

            @media only screen and (min-width: 1800px) {
                max-height: 130svh;
            }
        }

        .section-header {
            z-index: 2;
            bottom: 0;
            width: 85%;
            object-fit: contain;
            max-height: 50%;

            @media only screen and (min-width: 1700px) {
                width: 75%;
            }
        }
    }

    .section-2 {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
        background-color: white;
        overflow: hidden;
        padding: 75px 0 0 0;

        @media only screen and (min-width: 900px) {
            padding: 85px 0 0 0;
        }

        .background-container {
            position: absolute;
            height: 100svh;
            left: 0;
            width: 100%;

            @media only screen and (min-width: 1400px) {
                bottom: 0;
            }

            .background-single {
                position: absolute;
                object-fit: fill;
                width: 90%;
                max-width: 1300px;
                top: 150px;

                @media only screen and (min-width: 1400px) {
                    bottom: 0;
                    top: unset;
                }

                @media only screen and (min-width: 2000px) {
                    max-height: 45svh;
                    max-width: 1800px;
                }
            }
        }

        h3 {
            margin: 26px 0;
        }

        .products {
            margin-top: 30px;
            width: 90%;
            z-index: 2;
            max-width: 1500px;

            .proxies-container,
            .servers-container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @media only screen and (min-width: 1400px) {
                    flex-direction: row;
                }

                gap: 15px;
            }
        }

        @keyframes shake {
            0% {
                transform: translate(1px, 1px) rotate(0deg);
            }

            10% {
                transform: translate(-1px, -2px) rotate(-1deg);
            }

            20% {
                transform: translate(-3px, 0px) rotate(1deg);
            }

            30% {
                transform: translate(3px, 2px) rotate(0deg);
            }

            40% {
                transform: translate(1px, -1px) rotate(1deg);
            }

            50% {
                transform: translate(-1px, 2px) rotate(-1deg);
            }

            60% {
                transform: translate(-3px, 1px) rotate(0deg);
            }

            70% {
                transform: translate(3px, 1px) rotate(-1deg);
            }

            80% {
                transform: translate(-1px, -1px) rotate(1deg);
            }

            90% {
                transform: translate(1px, 2px) rotate(0deg);
            }

            100% {
                transform: translate(1px, -2px) rotate(-1deg);
            }
        }

        .enterprise-note {
            margin-top: 30px;
            margin-left: 5%;
            margin-right: 5%;
            padding: 8px 16px 8px 12px;
            border-radius: 19px;
            border: solid 1px #ededee;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            @media only screen and (min-width: 900px) {
                flex-direction: row;
            }

            svg {
                width: 36px;
                height: 36px;

                @media only screen and (min-width: 900px) {
                    width: 20px;
                    height: 20px;
                }
            }

            span {
                margin-left: 10px;
            }

            a {
                font-family: "Suisse-Medium";
                font-weight: normal;
                text-decoration: underline;
                color: #f9c23c;
                cursor: pointer;
            }

            &.shake {
                /* Start the shake animation and make the animation last for 0.5 seconds */
                animation: shake 0.5s;

                /* When the animation is finished, start again */
                animation-iteration-count: infinite;
            }
        }
    }

    .section-3 {
        height: 275px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .section-4 {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;

        .background-container {
            position: absolute;
            height: 100svh;
            right: 0;
            width: 100%;

            @media only screen and (min-width: 1400px) {
                bottom: 275px;
            }

            .background-single {
                transform: scaleX(-1);
                position: absolute;
                object-fit: fill;
                width: 90%;
                max-width: 1300px;
                top: 300px;
                right: 0;

                @media only screen and (min-width: 1400px) {
                    bottom: 0;
                    top: unset;
                }
                
                @media only screen and (min-width: 2000px) {
                    max-height: 45svh;
                    max-width: 1800px;
                }
            }
        }

        h3 {
            max-width: 400px;
        }

        .redirect-button {
            margin-top: 35px;
            font-size: 16px;
            font-family: "Suisse-Medium";
            font-weight: normal;
            height: 44px;

            svg {
                transform: rotate(-90deg);
            }
        }

        .feature-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 90%;
            padding-top: 30px;
            justify-content: space-between;
            max-width: 1270px;
            gap: 20px;

            .image-card {
                flex-grow: 1;
            }
        }
    }

    .section-5 {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
        margin-top: 100px;

        @media only screen and (min-width: 900px) {
            margin-top: 120px;
        }

        .background-container {
            position: absolute;
            height: 100svh;
            left: 0;
            width: 100%;
            top: 50px;

            @media only screen and (min-width: 1400px) {
                top: 0;
            }

            .background-single {
                position: absolute;
                object-fit: fill;
                width: 90%;
                max-width: 1300px;
                height: 35svh;
                top: 0;

                @media only screen and (min-width: 1400px) {
                    height: auto;
                }

                @media only screen and (min-width: 2000px) {
                    max-height: 45svh;
                    max-width: 1800px;
                }
            }
        }

        .review-container {
            display: flex;
            flex-direction: column;
            width: 90%;
            max-width: 1270px;
            gap: 20px;

            @media only screen and (min-width: 900px) {
                flex-wrap: wrap;
                max-height: 1800px;
            }
        }

        .carousel-root {
            max-width: 100%;

            .review-card {
                margin: 0 5%;
            }

            @media only screen and (min-width: 900px) {
                max-width: 90%;

                .review-card {
                    margin: unset;
                }
            }

            .carousel {
                display: flex;
                flex-direction: column-reverse;
            }

            .carousel-item {
                max-width: 100%;
                height: 100%;
            }

            .control-dots {
                position: relative;

                .dot {
                    border-radius: 1px;
                    background-color: #a9a9b4;
                    box-shadow: none;
                    width: 40px;
                    height: 4px;

                    &.selected {
                        background-color: #f8b30c;
                    }
                }
            }
        }
    }

    .section-6 {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2;
        margin-top: 25px;

        @media only screen and (min-width: 900px) {
            margin-top: 100px;
        }

        .background-container {
            position: absolute;
            height: 100svh;
            right: 0;
            width: 100%;
            pointer-events: none;

            @media only screen and (min-width: 1400px) {
                bottom: 50px;
            }

            .background-single {
                transform: scaleX(-1);
                position: absolute;
                object-fit: fill;
                width: 90%;
                max-width: 1300px;
                top: 100px;
                right: 0;
                height: 50%;

                @media only screen and (min-width: 1400px) {
                    bottom: 0;
                    top: unset;
                    height: unset;
                }

                @media only screen and (min-width: 2000px) {
                    max-height: 45svh;
                    max-width: 1800px;
                }
            }
        }

        .contact-container {
            width: 90%;
            margin-top: 55px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media only screen and (min-width: 900px) {
                flex-direction: row;
            }

            .calendly-container {
                @media only screen and (min-width: 900px) {
                    margin-left: 20px;
                }
            }
        }
    }

    .section-7 {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;

        @media only screen and (min-width: 900px) {
            margin-top: 120px;
        }

        .footer-container {
            width: 90%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 50px;
            max-width: 1800px;

            @media only screen and (min-width: 900px) {
                justify-content: space-evenly;
                width: 100%;
                gap: 20px;
            }

            .footer-column {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                min-width: 125px;

                @media only screen and (max-width: 899px) {
                    flex: 40% 0 0;

                    &:first-child {
                        flex: 100% 0 0;
                        width: 100%;
                    }
                }

                .footer-title {
                    font-size: 12px;
                    color: #4f4f51;
                }

                a {
                    font-family: "Suisse-Medium";
                    font-weight: normal;
                    text-decoration: none;
                    color: black;
                    margin-top: 22px;
                }

                .logo {
                    width: 185px;
                    object-fit: fill;
                }
            }
        }
    }

    .section-footer {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 70px;
        border-top: 1px solid #ededee;
        padding: 50px 80px;

        @media only screen and (min-width: 900px) {
            flex-direction: row;
            padding: 80px 100px;
        }

        p {
            display: flex;
            flex-direction: column;
            align-items: center;

            @media only screen and (min-width: 900px) {
                flex-direction: row;
                margin-left: auto;
            }

            .divider {
                display: none;

                @media only screen and (min-width: 900px) {
                    display: block;
                    margin: 0 5px;
                }
            }
        }

        span {
            color: #4f4f51;
            font-size: 12px;
            text-align: center;
        }

        .cc {
            margin-top: 30px;

            @media only screen and (min-width: 900px) {
                margin-top: 0;
            }
        }
    }

    .redirect-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 16px 4px 16px;
        border-radius: 24px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px #ededee;
        background-image: linear-gradient(to bottom, #fefefe, #fefefe);
        color: black;

        svg {
            margin-left: 12px;
        }
    }
}

.product-selector {
    padding: 4px;
    border-radius: 8px;
    background-color: #f7f7f8;
    z-index: 3;

    button {
        padding: 8px 16px;
        border-radius: 4px;
        background: none;
        border: none;
        color: #1d1d20;
        font-family: "Suisse-Medium";
        font-size: 14px;
        line-height: 1.14;
        cursor: pointer;

        &.selected {
            background-image: linear-gradient(to bottom, #fefefe, #fff);
            border: solid 1px #ededee;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        }

        &+button {
            margin-left: 0.5rem;
        }
    }
}