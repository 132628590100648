.login-modal {
    display: flex;
    flex-direction: column;
    max-width: 370px;

    .flex-row {
        width: 100%;
    }

    .forgot {
        cursor: pointer;
        margin-left: auto;
        font-family: "Suisse-Regular";
        font-size: 12px;
    }
}