.resi-card {

    .card-header {
        .product-selector {
            margin-left: 15px;

            button {
                padding: 4px 16px;
            }
        }
    }

    .card-body {
        margin: 0 -10px;

        .product-selector {
            margin-left: auto;
            margin-right: 10px;

            button {
                padding: 4px 16px;
            }
        }

        canvas {
            margin-top: 20px;
        }

        .chart-container {
            width: 100%;
            height: 100%;
            padding: 0 10px;

            &.hidden {
                display: none;
            }
        }
    }
}