.dashboard-page-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 1135px;
    padding-bottom: 36px;

    h1 {
        font-size: 24px;

        @media only screen and (min-width: 992px) {
            font-size: 32px;
        }
    }

    .subtitle-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-top: 20px;

        @media only screen and (min-width: 992px) {
            margin-top: 24px;
        }
    }

    .product-selector {
        margin-left: auto;
        background: #f0f0f0;
        border: 1px solid white;
    }

    .plans-container {
        display: flex;
        flex-direction: row;
        gap: 16px;
        flex-wrap: wrap;
        width: calc(100% + 32px);
        max-width: calc(100% + 32px);
        margin: 12px -16px 0 -16px;

        @media only screen and (min-width: 992px) {
            width: 100%;
            max-width: 100%;
            margin: unset;
            gap: 20px;
            margin-top: 12px;
        }

        .card {
            margin: 0 16px;

            @media only screen and (min-width: 992px) {
                margin: 0;
                height: 210px;
                width: 238px;
                flex-grow: 1;
            }

            @media only screen and (min-width: 1450px) {
                width: 268px;
                height: 240px;
            }
        }

        .slide {
            min-width: 75% !important;
        }
    }

    .products-container {
        display: flex;
        flex-direction: row;
        margin-top: 12px;
        gap: 20px;
        width: calc(100% + 32px);
        max-width: calc(100% + 32px);
        margin: 12px -16px 0 -16px;

        @media only screen and (min-width: 992px) {
            margin: unset;
            margin-top: 12px;
            width: 100%;
            max-width: 100%;
            overflow: hidden;
            flex-wrap: wrap;
        }

        .proxy-card {
            margin: 0 16px;

            @media only screen and (min-width: 992px) {
                max-height: 480px;
                flex: 1 0 325px;
                margin: 0;
            }

            @media only screen and (min-width: 1450px) {
                flex: 0 0 415px;
            }

            .details-row {
                svg {
                    min-width: 16px;
                    min-height: 16px;
                }

                overflow: hidden;

                span {
                    text-align: start;
                    text-wrap: nowrap;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }

        .server-card {
            margin: 0 16px;
            flex: 1 1 auto;

            @media only screen and (min-width: 992px) {
                max-height: 480px;
                flex: 0 0 435px;
                margin: 0;

                .server-card-title {
                    font-size: 32px;
                }

                .price-container {
                    margin-top: unset;
                }

                .details-container {
                    margin-bottom: unset;
                }

                #buy-now {
                    margin-top: 32px;
                }
            }
            
            .server-card-title-container {
                align-items: center;
            }
        }
    }
}
