* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html,
body {
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p,
span {
  font-family: "Suisse-Regular";
  font-weight: normal;
  font-size: 14px;
  margin: 0;
}

@font-face {
  font-family: "Suisse-Bold";
  src: local("Suisse Intl Bold"),
    url("./assets/fonts/SuisseIntl-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Inter-Regular";
  src: local("Inter Regular"),
    url("./assets/fonts/Inter-Regular.ttf") format("truetype");
  font-display: swap;
}