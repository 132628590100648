.contact-form-container {
    padding: 20px;
    border-radius: 8px;
    border: solid 1px #ddd;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    z-index: 2;

    @media only screen and (min-width: 900px) {
        max-width: 560px;
    }
    
    .urgent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-top: solid 1px #ddd;
        padding: 20px 40px 0 40px;
        line-height: 1.5;
        text-align: center;
    }

    .form-result, .error {
        margin:auto;
        text-align: center;
    }
}