.image-card {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 0 0 24px;
    border-radius: 8px;
    border: solid 1px #ededee;
    z-index: 2;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);

    .image-container {
        display: flex;
        flex-grow: 1;

        img {
            margin: auto;
            max-width: 100%;
            object-fit: contain;
        }
    }

    span {
        font-size: 16px;
    }

    .image-title {
        padding: 0 24px;
        font-family: "Suisse-Medium";
        font-weight: normal;
    }

    .image-description {
        padding: 12px 24px 0px 24px;
        white-space: normal;

        @media only screen and (min-width: 900px) {
            white-space: pre-wrap;
        }
    }
}