.popup-modal {
    z-index: 1000;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #ededee;
    margin: 16px;
    max-height: 90svh;
    overflow: auto;

    .modal-header {
        display: flex;
        flex-direction: column;
        padding: 20px;
        border-bottom: solid 1px #ededee;

        .modal-title {
            font-family: "Suisse-Medium";
            font-weight: normal;
            font-size: 16px;
            color: black;
            margin: 0;
        }

        .modal-subtitle {
            margin-top: 8px;
        }
    }

    .modal-body {
        padding: 20px;
    }

    .modal-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px 20px 20px;

        hr {
            border-top: 1px solid #ededee;
            margin-bottom: 20px;
            width: 100%;
        }
    }
}

.popup-modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;
    position: fixed;
    touch-action: none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
