.mobile-selector-card {
    padding: 0;
    width: unset;
    height: unset;
    flex: 1 1 364px;

    .card-header {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px solid #ededee;

        .card-title {
            font-family: "Suisse-Medium";
            font-weight: normal;
            font-size: 16px;
        }

        .card-subtitle {
            margin-top: 8px;
            font-size: 14px;
        }
    }

    .card-body {
        padding: 20px;
        justify-content: flex-start;

        form {
            width: 100%;
        }
    }
}