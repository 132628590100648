.company-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    width: 100%;

    @media only screen and (min-width: 992px) {
        margin-top: 50px;
    }

    h1 {
        font-size: 30px;

        @media only screen and (min-width: 992px) {
            font-size: 40px;
        }
    }

    .text-container {
        margin-top: 25px;
        max-width: 90%;

        @media only screen and (min-width: 992px) {
            max-width: 75%;
        }
    }
}