#root {
  display: flex;
  flex-direction: column;
}

img {
  object-fit: none;
  pointer-events: none;
}

button {
  cursor: pointer;
  color: black;
}

.chip-header {
  background-color: #f7f7f8;
  border-radius: 19px;
  padding: 10px 12px;

  p {
    font-family: "Suisse-Medium";
    font-size: 14px;
    color: #7d7e89;
    margin: 0;

    @media only screen and (min-width: 900px) {
      font-size: 16px;
    }
  }
}

.text-underline {
  font-family: "Suisse-Medium";
  font-weight: normal;
  text-decoration: underline;
  color: #f9c23c;
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  .form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      font-family: "Suisse-Regular";
      font-size: 14px;
    }

    textarea {
      resize: none;
    }

    select {
      -webkit-appearance: none;
      appearance: none;
      color: black;
    }

    select:invalid {
      color: grey;
    }

    .error {
      margin-top: 5px;
      font-size: 12px;
    }
  }

  .error {
    color: rgb(197, 66, 66);
    font-size: 14px;
  }

  button {
    font-family: "Suisse-Medium";
    font-weight: normal;
    font-size: 14px;
    color: #1d1d20;
    cursor: pointer;
  }

  .w-50 {
    flex: 100% 1 1;

    @media only screen and (min-width: 992px) {
      flex: 45% 1 1;
    }
  }

  .w-100 {
    flex: 100% 1 1;
  }
}

input,
textarea,
select {
  font-family: "Suisse-Regular";
  font-size: 16px;
  margin-top: 9px;
  border-radius: 4px;
  border: solid 1px #ddd;
  background-color: #fff;
  padding: 10px 12px;
  width: 100%;

  @media only screen and (min-width: 992px) {
    font-size: 14px;
  }
}

#submit-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  border-radius: 4px;
  border: solid 1px #ecb839;
  background-color: #f9c23c;

  &:disabled {
    opacity: 50%;
  }
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid darkgray;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 2s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

::-webkit-scrollbar {
  width: 0px;

  @media only screen and (min-width: 992px) {
    width: 20px;
  }
}

::-webkit-scrollbar-track {
  background-color: transparent;
  width: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #cecece;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.slide-in-right {
  transform: translateX(100%);
  visibility: hidden;
  animation: slide-in-right-animation 0.3s ease-out forwards;
}

@keyframes slide-in-right-animation {
  from {
    transform: translateX(100%);
    visibility: visible;
  }

  to {
    transform: translateX(0);
    visibility: visible;
  }
}

div:focus-visible {
  outline: none;
}

table {
  border-collapse: collapse;
  width: 100%;

  .button-container {
    width: 80px;
  }

  button {
    float: right;
    font-size: 13px;
    padding: 4px 8px;
    height: 24px;
    border: none;
    border-radius: 4px;
    background: darkgray;
    margin-left: auto;

    &.selected {
      border: solid 1px #ecb839;
      background-color: #f9c23c;
    }
  }
}

th,
td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.default-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border-radius: 5px;
  border: 1px solid grey;
  padding: 10px 15px;
  height: 28px;
  font-family: "Suisse-Medium";
}

.pos-rel {
  position: relative;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  font-family: "Inter-Medium";
  cursor: pointer;

  .tooltip-content-container {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: -75px;
    right: -190px;

    padding: 25px;
  }

  &:hover .tooltip-content-container {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }

  .tooltip-content {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #ededee;
    background-color: #fff;
    width: 160px;

    a {
      padding: 12px 16px;
      margin: 0;
      height: unset;

      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }

    hr {
      margin: 0;
      height: 1px;
      border: none;
      border-top: solid 1px #f0f0f0;
    }
  }
}


.table-page-container {
  padding-bottom: 50px !important;

  @media only screen and (max-width: 991px) {
      padding-bottom: 120px !important;
  }

  .table-card {
      margin-top: 20px;
      width: 1200px;
      max-width: 100%;
      height: unset;

      .search-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          max-width: 100%;

          @media only screen and (min-width: 992px) {
              margin: 0 10px;
              flex-direction: row;
              align-items: flex-start;
          }

          span {
              margin-top: 10px;
              font-size: 16px;
          }
      }

      .search-input {
          margin-left: auto;
          width: 400px;
          max-width: 100%;
      }

      table {
          margin-top: 20px;
          width: 100%;
          display: block;
          overflow-x: auto;
          white-space: nowrap;

          thead {
              width: 100%;
          }

          tbody {
              display: table;
              width: 100%;

              .clickable {
                cursor: pointer;
                transition: all 0.2s ease;

                &:hover {
                  opacity: 0.4;
                }
              }
          }
      }
  }

  .nav-button-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 25px;
      justify-content: flex-end;

      button {
          height: 35px;
      }

      button+button, a+a, button+a, a+button {
          margin-left: 10px;
      }
  }

  button, a {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: none;
      border-radius: 5px;
      border: 1px solid grey;
      padding: 10px 15px;
      height: 28px;
      font-family: "Suisse-Medium";
      text-decoration: none;
      font-size: 13px;
      margin-left: auto;
      color: black
  }
}