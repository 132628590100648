.generate-resi-card {
    padding: 0;
    width: unset;
    height: unset;
    flex: 1 1 364px;

    .card-header {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px solid #ededee;

        .card-title {
            font-family: "Suisse-Medium";
            font-weight: normal;
            font-size: 16px;
        }

        .card-subtitle {
            margin-top: 8px;
            font-size: 14px;
        }
    }

    .card-body {
        padding: 20px;
        justify-content: flex-start;

        form {
            width: 100%;
        }

        optgroup {
            font-weight: 600;
            color: gray;

            option {
                color: black;
            }
        }

        [tooltip] {
            position: relative;

            &:hover {
                &:after {
                    // A wild attribute appeared
                    content: attr(tooltip);

                    // Zeroing
                    position: absolute;
                    transform: translate(-20%, -100%);
                    left: 50%;

                    // Position
                    top: -10px; // (10 Pixel above div)

                    // Important Styling
                    font-size: 14px;
                    min-width: 300px;
                    padding: 0.8rem;

                    border-radius: 8px;
                    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                    border: solid 1px #ededee;
                    background-color: #fff;
                }
            }
        }

        .tooltip-container {
            position: absolute;
            margin-left: 55px;
        }

        .info-icon {
            display: block;
            width: 17px;
            height: 17px;
            object-fit: contain;
        }
    }
}