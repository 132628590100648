.blog-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
    align-self: center;

    @media only screen and (min-width: 1300px) {
        max-width: unset;
        align-self: unset;
    }

    .top-container {
        width: 100%;
        max-width: 1200px;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        gap: 24px;

        @media only screen and (min-width: 1200px) {
            flex-direction: row;
            margin-top: 50px;
        }

        .left-container {
            flex: 0 1 70%;

            .featured-container {
                position: relative;
                cursor: pointer;
                margin-top: 20px;
                max-height: 500px;
                height: auto;
                background-size: cover;
                background-repeat: no-repeat;
                aspect-ratio: 1.6;
                border-radius: 16px;
                display: flex;
                flex-direction: column;
                padding: 36px 24px;

                @media only screen and (min-width: 1200px) {
                    &::before {
                        content: "";
                        border-radius: 16px;
    
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,0.6) 100%);
                    }
                }

                .title {
                    font-size: 32px;
                    color: white;
                    font-family: "Suisse-SemiBold";
                    margin-top: auto;
                    z-index: 2;
                }

                .sub-title {
                    font-size: 18px;
                    color: white;
                    margin-top: 16px;
                    z-index: 2;

                }
            }

            .title-container {
                margin-top: 12px;
                display: flex;
                flex-direction: column;

                .title {
                    font-size: 18px;
                    font-family: "Suisse-SemiBold";
                }

                .sub-title {
                    font-size: 14px;
                    margin-top: 12px;
                }
            }
        }

        .right-container {
            flex: 1 0 30%;

            .recent-list-container {
                margin-top: 20px;
                display: flex;
                flex-direction: column;

                article {
                    display: flex;
                    flex-direction: row;
                    gap: 16px;
                    cursor: pointer;

                    .image-container {
                        min-width: 80px;
                        width: 80px;
                        min-height: 80px;
                        height: 80px;
                        border-radius: 8px;
                        background-repeat: no-repeat;

                        @media only screen and (min-width: 1200px) {
                            min-width: 100px;
                            width: 100px;
                            min-height: 100px;
                            height: 100px;
                        }
                    }

                    .article-details {
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                        width: 100%;

                        @media only screen and (min-width: 1200px) {
                            max-width: 240px;
                        }

                        .flex-row {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .clock-icon {
                                margin-left: auto;
                            }
                        }

                        .date {
                            font-size: 12px;
                            color: #65686D;

                            @media only screen and (min-width: 1200px) {
                                font-size: 16px;
                            }
                        }

                        .title {
                            font-family: "Suisse-Medium";
                            font-size: 16px;
                            max-height: 72px;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            @media only screen and (min-width: 1200px) {
                                font-size: 20px;
                            }
                        }

                        .read-time {
                            font-size: 12px;
                            color: #65686d;
                            margin-left: 3px;

                            @media only screen and (min-width: 1200px) {
                                font-size: 16px;
                            }
                        }
                    }
                }

                hr {
                    display: block;
                    margin: 12px 0;
                    border: 1px solid #ededee;


                    @media only screen and (min-width: 1200px) {
                        margin: 19px 0;
                    }
                }
            }
        }
    }

    .bottom-container {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        width: 100%;
        max-width: 1200px;

        .featured-list-container {
            margin-top: 20px;
            display: flex;
            flex-direction: column;

            @media only screen and (min-width: 1200px) {
                flex-direction: row;
                gap: 24px;
            }

            article {
                flex: 0 0 25%;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                gap: 16px;

                @media only screen and (min-width: 1200px) {
                    display: flex;
                    flex-direction: column;
                    max-width: 290px;
                }

                .article-details {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .date {
                        font-size: 12px;
                        color: #65686D;

                        @media only screen and (min-width: 1200px) {
                            font-size: 16px;
                        }
                    }
                }

                .title {
                    font-family: "Suisse-Medium";
                    font-size: 16px;
                    max-height: 72px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-top: 16px;

                    @media only screen and (min-width: 1200px) {
                        font-size: 20px;
                    }
                }

                .dot {
                    color: #65686d;
                    margin: 0px 10px;
                }

                .flex-row {
                    @media only screen and (min-width: 1200px) {
                        margin-top: 13px;
                    }

                    .clock-icon {
                        margin-left: auto;

                        @media only screen and (min-width: 1200px) {
                            margin-left: unset;
                        }
                    }

                    .read-time {
                        color: #65686d;
                        margin-left: 3px;
                        font-size: 12px;

                        @media only screen and (min-width: 1200px) {
                            font-size: 16px;
                        }
                    }
                }

                .image-container {
                    border-radius: 16px;
                    background-size: auto;
                    background-position: bottom;
                    background-repeat: no-repeat;
                    min-width: 80px;
                    width: 80px;
                    min-height: 80px;
                    height: 80px;

                    @media only screen and (min-width: 1200px) {
                        width: 290px;
                        height: 160px;
                    }
                }
            }

            hr {
                display: block;
                margin: 12px 0;
                border: 1px solid #ededee;

                @media only screen and (min-width: 1200px) {
                    display: none;
                }
            }
        }
    }

    .dashboard-subtitle {
        color: #7d7e89;
        font-family: "Suisse-Regular";
        font-size: 14px;
        text-transform: uppercase;
    }
}