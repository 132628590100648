.section-7 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    width: 100%;

    @media only screen and (min-width: 900px) {
        margin-top: 120px;
    }

    .footer-container {
        width: 90%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 50px;
        max-width: 1800px;

        @media only screen and (min-width: 900px) {
            justify-content: space-evenly;
            width: 100%;
            gap: 20px;
        }

        .footer-column {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            min-width: 125px;

            @media only screen and (max-width: 899px) {
                flex: 40% 0 0;

                &:first-child {
                    flex: 100% 0 0;
                    width: 100%;
                }
            }

            .footer-title {
                font-size: 12px;
                color: #4f4f51;
            }

            a {
                font-family: "Suisse-Medium";
                font-weight: normal;
                text-decoration: none;
                color: black;
                margin-top: 22px;
            }

            .logo {
                width: 185px;
                object-fit: fill;
            }
        }
    }
}

.section-footer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 70px;
    border-top: 1px solid #ededee;
    padding: 50px 80px;
    width: 100%;

    @media only screen and (min-width: 900px) {
        flex-direction: row;
        padding: 80px 100px;
    }

    p {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media only screen and (min-width: 900px) {
            flex-direction: row;
            margin-left: auto;
        }

        .divider {
            display: none;

            @media only screen and (min-width: 900px) {
                display: block;
                margin: 0 5px;
            }
        }
    }

    span {
        color: #4f4f51;
        font-size: 12px;
        text-align: center;
    }

    .cc {
        margin-top: 30px;

        @media only screen and (min-width: 900px) {
            margin-top: 0;
        }
    }
}