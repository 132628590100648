.details-card {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #ededee;
    background-color: #fff;
    flex: 65% 0 0;
    z-index: 2;

    @media only screen and (min-width: 1400px) {
        max-height: 570px;
    }

    @media only screen and (max-width: 1399px) {
        margin-top: 15px;
    }

    .details-card-header {
        display: flex;
        flex-direction: column;
        padding: 32px 24px 0px;

        .details-card-header-title {
            font-size: 16px;
            font-family: "Suisse-Medium";
            line-height: 1.5;
        }

        .details-card-header-subtitle {
            margin-top: 16px;
            line-height: 1.71;
        }
    }

    .details-card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        padding: 32px 24px 24px;
        margin: -16px;

        @media only screen and (min-width: 900px) {
            flex-direction: row;
            flex-wrap: wrap;
        }

        .details-keypoint {
            display: flex;
            flex-direction: column;
            flex: 29% 0 0;
            margin: 16px;

            .details-keypoint-title-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                
                .details-keypoint-title {
                    font-size: 16px;
                    font-family: "Suisse-Medium";
                    line-height: 1.5;
                    color: #F8B30C;
                    margin-left: 10px;
                }
            }

            .details-keypoint-desc {
                margin-top: 16px;
                line-height: 1.71;
            }
        }
    }

    .details-card-footer {
        background: #EDEDEE;
        padding: 2px 14px 2px 14px;
        display: flex;
        flex-direction: column;
        border-radius: 0 0 8px 8px;

        @media only screen and (min-width: 900px) {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .details-card-footer-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 30% 0 1;
            margin: 10px;

            span {
                margin-left: 12px;
            }
        }
    }
}