.user-modal {
    display: flex;
    flex-direction: column;
    min-width: 900px;
    max-width: 100%;

    .card-title {
        margin-right: 15px;
    }

    .modal-body {
        overflow-y: auto;

        .user-header-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .name-container {
                margin-left: 20px;
                display: flex;
                flex-direction: column;

                span {
                    &.bold {
                        font-size: 18px;
                        font-family: "Suisse-Medium";
                        margin-bottom: 3px;
                    }

                    font-size: 16px;
                }
            }

            .avatar-container {
                display: flex;
                min-width: 64px;
                max-width: 64px;
                min-height: 64px;
                max-height: 64px;
                border-radius: 50%;

                img {
                    width: 64px;
                    height: 64px;
                    object-fit: contain;
                    border-radius: 50%;
                }
            }

            .button-container {
                display: flex;
                flex-direction: row;
                gap: 10px;
                margin-left: auto;
            }
        }

        .user-data-container {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .data-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 16px;

                + .data-row {
                    margin-top: 3px;
                }

                label {
                    margin-right: 15px;
                    font-family: "Suisse-Medium";
                }
            }
        }

        .title {
            display: block;
            margin-top: 30px;
            margin-bottom: 10px;
            font-size: 16px;
            font-family: "Suisse-Medium";
        }

        .resi-data {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            gap: 5px;
            cursor: pointer;
            svg {
                width: 12px;
                height: 12px;
            }
        }

        .tooltip-content-container {
            padding: 0.8rem;
            width: 300px;
            font-size: 14px;

            .tooltip-content {
                width: 300px;
            }
        }
    }
}
