.result-card {
    padding: 20px;
    width: unset;
    height: unset;
    flex: 1 1 750px;

    .card-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        form {
            height: 100%;
            width: 100%;

            textarea {
                font-family: 'IBM Plex Mono', monospace;
                font-size: 14px;
                border: 1px solid #ededee;
                height: 100%;
                min-height: 230px;
            }
        }

        .button-row {
            display: flex;
            flex-direction: row;
            margin-top: 12px;
            gap: 20px;
            width: 100%;

            button {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 10px 0;
                border-radius: 4px;
                border: solid 1px #ededee;
                background-color: white;
                font-family: "Suisse-Medium";
            }
        }
    }
}