.overview-page-container {

    .analytics-overview-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-top: 20px;
        gap: 15px;
        flex-wrap: wrap;

        @media only screen and (max-width: 991px) {
            padding-bottom: 120px !important;
        }
    }

    .mt-20 {
        margin-top: 20px;
    }

    .swap-card {
        width: 500px;
        max-width: 100%;
    }
}