.data-card {
    min-width: 150px;
    height: 150px;

    .card-body {
        display: flex;
        flex-direction: column;

        .centered {
            display: flex;
            flex-direction: column;
            margin: auto;
            text-align: center;

            .title {
                color: grey;
                margin-top: 10px;
                font-size: 14px;
            }

            .subTitle {
                display: block;
                color: rgb(185, 183, 183);
                margin-top: 10px;
                font-size: 11px;
            }

            .value {
                flex-grow: 1;
                font-size: 36px;
                font-family: "Suisse-Medium";
            }
        }
    }
}
