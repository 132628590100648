.residential-page-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 1550px;
    padding-bottom: 20px !important;

    @media only screen and (max-width: 991px) {
        padding-bottom: 120px !important;
    }

    .generate-container {
        margin-top: 12px;
        display: flex;
        flex-direction: row;
        gap: 20px;
        flex-wrap: wrap;
        width: 100%;

        .result-card {
            flex: 1 1 500px;

            @media only screen and (min-width: 1450px) {
                flex: 1 1 750px;
            }
        }
    }

    .topup-status-container {
        margin-top: 32px;
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        width: 100%;
        gap: 20px;

        @media only screen and (min-width: 1445px) {
            flex-direction: row;
        }

        .topup-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 1 364px;

            .proxy-card {
                margin-top: 12px;
                width: 100%;

                .details-row {
                    svg {
                        min-width: 16px;
                        min-height: 16px;
                    }

                    overflow: hidden;

                    span {
                        text-align: start;
                        text-wrap: nowrap;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }
            }
        }

        .status-container {
            @media only screen and (min-width: 1445px) {
                flex: 1 1 750px;
            }

            .resi-card {
                height: 275px;
                width: unset;
                margin-top: 12px;

                .expiry-container {
                    span {
                        font-size: 14px;
                    }
                }

                @media only screen and (min-width: 992px) {
                    height: 470px;
                }
            }
        }
    }
}