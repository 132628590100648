.order-details-card {
    padding: 0;
    width: unset;
    height: unset;
    flex: 1 1 364px;

    .card-header {
        padding: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #ededee;

        .card-title {
            font-family: "Suisse-Medium";
            font-weight: normal;
            font-size: 16px;
            text-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
            max-width: 250px;
        }

        .card-subtitle {
            margin-top: 8px;
            font-size: 14px;
        }

        .expiry-container {
            margin: 0;
            margin-left: auto;
        }
    }

    .card-body {
        padding: 20px;
        justify-content: flex-start;

        .form {
            width: 100%;

            .order-details-container {
                display: flex;
                flex-direction: row;
                gap: 30px;

                .detail-item {
                    display: flex;
                    flex-direction: column;

                    .title {
                        font-size: 12px;
                        color: #7d7e89;
                        text-transform: uppercase;
                    }

                    .value {
                        font-size: 16px;
                        color: #1d1d20;
                        margin-top: 8px;
                    }
                }
            }

            #submit-form {
                margin-top: 23px;
                font-family: "Suisse-Medium";
            }
        }
    }
}