.isp-admin-page-container{

    .result-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-top: 20px;
        gap: 15px;
        flex-wrap: wrap;
        
        .result-card {
            width: 100%;
            max-width: 750px;
        }
    }

    .mt-20 {
        margin-top: 20px;
    }

    .mt-0 {
        margin-top: 0;
    }

    .swap-card {
        width: 500px;
        max-width: 100%;
    }

    .stock-container {
        display: flex;
        margin-top: 20px;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        gap: 10px;
        max-width: 1965px;

        .stock-item {
            flex: 1;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            height: unset;

            .stock-server {
                display: flex;
                font-size: 1rem;
                font-weight: 600;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                gap: 3px;
                white-space: nowrap;

                .info-icon {
                    display: block;
                    width: 17px;
                    height: 17px;
                    object-fit: contain;
                }

                .tooltip-content-container {
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    padding: 10px;
                    .tooltip-content {
                        padding: 10px;
                        font-weight: 400;
                        font-size: 0.8rem;

                        .tooltip-subnets {
                            list-style-type: none;
                            padding: 0;
                            margin: 0;
                            li{
                                margin-top: 5px;
                            }

                            &-title{
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            .stock-available {
                white-space: nowrap;
            }
        }
    }
}