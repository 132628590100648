.calendly-container {
    padding: 10px;
    border-radius: 8px;
    border: solid 1px #ddd;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    margin-top: 20px;
    z-index: 2;

    @media only screen and (min-width: 900px) {
        margin-top: 0;
        width: 320px;
        max-width: 320px;
        height: 643px;
    }
}