.card {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;

    width: 90%;
    height: 240px;

    @media only screen and (min-width: 992px) {
        width: 268px;
        height: 240px;
    }

    .card-header {
        display: flex;
        flex-direction: row;
        align-items: center;

        .card-icon {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 28px;
                object-fit: contain;
            }
        }

        .card-title {
            span {
                text-wrap: nowrap;
            }

            font-size: 16px;
            max-width: 55svw !important;

            @media only screen and (min-width: 992px) {
                max-width: 75% !important;
            }
        }

        .expiry-container {
            display: flex;
            align-items: center;
            margin-left: auto;
            padding: 5px 8px 4px;
            border-radius: 2px;
            background-color: #f0f0f0;

            span {
                text-wrap: nowrap;
                font-size: 10px;
                text-transform: uppercase;
            }
        }
    }

    .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;

        img {
            width: auto;
        }

        .notice {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 15px;

            span {
                font-size: 12px;

                &+span {
                    margin-top: 5px;
                }
            }

            br {
                font-family: "Suisse-Medium";
                font-weight: normal;
            }
        }
    }
}