.twofactor-modal {
    display: flex;
    flex-direction: column;
    max-width: 375px;

    .twofactor-container {
        display: flex;
        flex-direction: row;
        gap: 12px;

        input {
            aspect-ratio: 1;
            text-align: center;
            font-family: "Suisse-Regular";
            font-size: 28px;
            height: 44px;
            padding: 0;
        }
    }

    .text-underline {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: none;
        border: none;
        font-size: 14px;

        .loader {
            width: 14px;
            height: 14px;
            margin-left: 2px;
            margin-right: 5px;
        }

        &:disabled {
            cursor: unset;
        }
    }

    .modal-footer {
        .error {
            text-align: center;
            margin-top: 5px;
        }
    }
}