.dashboard-container {
    display: flex;
    flex-direction: column;
    height: 100svh;
    max-height: 100svh;
    overflow: hidden;
    background-color: #f0f0f0;
    align-items: flex-start;

    @media only screen and (min-width: 992px) {
        flex-direction: row;
    }

    .sidebar-container {
        display: flex;
        flex-direction: row;
        background-color: white;
        padding: 12px;
        height: 56px;
        width: 100%;

        @media only screen and (min-width: 992px) {
            min-width: 260px;
            max-width: 260px;
            height: 100svh;
            flex-direction: column;
        }

        .sidebar-body-container {
            display: none;
            flex-direction: column;
            flex-grow: 1;

            @media only screen and (min-width: 992px) {
                display: flex;
            }

            .sub-title {
                font-size: 11px;
                color: #7d7e89;
                margin-top: 20px;
            }

            .expiry-container {
                display: flex;
                align-items: center;
                margin-left: auto;
                padding: 5px 8px 4px;
                border-radius: 2px;
                background-color: #f0f0f0;

                span {
                    text-wrap: none;
                    font-size: 10px;
                    text-transform: uppercase;
                }
            }
        }

        .sidebar-footer-container {
            display: none;

            @media only screen and (min-width: 992px) {
                display: block;
            }

            .sidebar-profile-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 40px;
                margin-bottom: 6px;

                .avatar-container {
                    display: flex;
                    min-width: 32px;
                    max-width: 32px;
                    min-height: 32px;
                    max-height: 32px;
                    border-radius: 50%;

                    img {
                        width: 32px;
                        height: 32px;
                        object-fit: contain;
                    }
                }

                .profile-details-container {
                    display: flex;
                    flex-direction: column;
                    flex: 1 1 auto;
                    margin: 0 8px;

                    span {
                        color: #4f4f51;
                        font-size: 12px;
                        text-wrap: nowrap;
                        text-overflow: ellipsis;
                        max-width: 155px;
                        overflow: hidden;
                    }

                    .name {
                        color: #202023;
                        font-size: 14px;
                    }
                }

                button {
                    cursor: pointer;
                    background: none;
                    border: none;
                    flex: 0 1 20px;
                }
            }
        }

        .tooltip-container {
            position: relative;
            display: inline-block;
            font-family: "Inter-Medium";
            cursor: pointer;

            .tooltip-content-container {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                top: -75px;
                right: -190px;

                padding: 25px;
            }

            &:hover .tooltip-content-container {
                z-index: 10;
                opacity: 1;
                visibility: visible;
                transform: translate(0, -20px);
                transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
            }

            .tooltip-content {
                display: flex;
                flex-direction: column;
                border-radius: 8px;
                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                border: solid 1px #ededee;
                background-color: #fff;
                width: 160px;

                a {
                    padding: 12px 16px;
                    margin: 0;
                    height: unset;

                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 5px;
                    }
                }

                hr {
                    margin: 0;
                    height: 1px;
                    border: none;
                    border-top: solid 1px #f0f0f0;
                }
            }
        }

        a {
            display: flex;
            align-items: center;
            flex-direction: row;
            height: 32px;
            font-family: "Suisse-Regular";
            font-size: 14px;
            color: black;
            text-decoration: none;
            margin-top: 10px;
            padding: 4px;
            border-radius: 4px;
            cursor: pointer;

            img {
                width: 24px;
                height: 24px;
                object-fit: contain;
                margin-right: 4px;
            }

            &.highlight {
                &.active {
                    backdrop-filter: blur(5px);
                    background-color: #f0f0f0;
                }
            }

            & + a {
                margin-top: 4px;
            }
        }

        .logo {
            width: 190px;
            object-fit: cover;
            cursor: pointer;
            pointer-events: unset;
        }

        .toggle-icon {
            display: block;
            margin-left: auto;
            background: none;
            border: none;
            cursor: pointer;

            @media only screen and (min-width: 992px) {
                display: none;
            }
        }
    }

    .dashboard-body-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        @media only screen and (min-width: 992px) {
            max-height: 100%;
            overflow: hidden;
        }

        .dashboard-title-container {
            display: none;
            align-items: center;
            width: 100%;
            flex: 0 0 56px;
            background: white;
            border-left: 1px solid #f0f0f0;
            padding: 0 24px;

            @media only screen and (min-width: 992px) {
                display: flex;
            }

            span {
                font-family: "Suisse-Medium";
                font-size: 20px;
                text-transform: capitalize;
            }

            .swap {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: auto;

                span {
                    margin-left: 5px;
                    font-family: "Suisse-Regular";
                    font-size: 14px;
                    text-transform: unset;
    
                    a {
                        cursor: pointer;
                        color: #F9C23C;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .dashboard-page {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px 16px 50px 16px;

        @media only screen and (min-width: 992px) {
            padding: 32px 24px 0 24px;
            overflow-y: auto;
        }

        .dashboard-subtitle {
            font-family: "Suisse-Regular";
            font-size: 14px;
            text-transform: uppercase;
            color: #7d7e89;
        }
    }
}

.dashboard-mobile-overlay-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    border-radius: 8px;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding: 8px 17px 10px 7px;
    z-index: 100;

    position: fixed;
    bottom: 10px;
    left: 50%;
    translate: -50%;
    width: 360px;

    @media only screen and (min-width: 992px) {
        display: none;
    }

    a {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        align-items: center;
        color: #202023;
        font-family: "Suisse-Regular";
        font-size: 9px;
        text-transform: uppercase;
        cursor: pointer;

        &.active {
            img {
                filter: invert(92%) sepia(12%) saturate(5110%) hue-rotate(328deg) brightness(106%) contrast(95%);
            }
        }

        img {
            margin-bottom: 5px;
        }
    }
}

.dashboard-mobile-nav {
    position: absolute;
    display: none;
    flex-direction: column;
    touch-action: none;
    background: #202023;
    width: 100vw;
    height: 100svh;
    z-index: 999;
    padding: 16px;

    @media only screen and (max-width: 991px) {
        &.open {
            display: flex;
        }
    }

    button {
        background: none;
        border: none;
    }

    .mobile-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        img {
            width: 185px;
            object-fit: contain;
            margin-right: auto;
        }
    }

    .mobile-body {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .avatar-container {
            display: flex;
            min-width: 64px;
            max-width: 64px;
            min-height: 64px;
            max-height: 64px;
            border-radius: 50%;

            img {
                width: 64px;
                height: 64px;
                object-fit: contain;
            }
        }

        .profile-details-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 8px;

            span {
                color: #f7f8fa;
                font-size: 12px;
            }

            .name {
                font-size: 14px;
                margin-bottom: 4px;
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 0;
            align-items: center;

            hr {
                border-top: 1px solid white;
                width: 120px;
                margin-top: 30px;
                margin-bottom: 2px;
            }
        }

        li {
            list-style: none;
            margin-top: 28px;

            a {
                font-family: "Suisse-Medium";
                font-size: 30px;
                color: white;
                text-decoration: none;
            }

            & + li {
                margin-top: 1.5rem;
            }
        }
    }

    .mobile-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: "Suisse-Regular";
        font-size: 12px;
        color: white;
        text-align: center;

        p,
        a {
            font-size: 12px;
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
}

.carousel-root {
    max-width: 100%;

    .carousel {
        display: flex;
        flex-direction: column-reverse;

        & + .carousel {
            height: 0px;
        }
    }

    .carousel-item {
        max-width: 100%;
        height: 100%;

        @media only screen and (min-width: 992px) {
            display: flex;
        }
    }

    .control-dots {
        position: relative;

        .dot {
            border-radius: 50%;
            background-color: #a9a9b4;
            box-shadow: none;
            width: 6px;
            height: 6px;

            &.selected {
                border-radius: 8px;
                width: 40px;
                background-color: #f8b30c;
            }
        }
    }
}

.empty-page-container {
    display: flex;
    width: 100%;
    height: calc(100svh - 112px);
    align-items: center;
    justify-content: center;

    .empty-container {
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
        padding-bottom: 40px;

        img {
            width: 225px;
            object-fit: contain;
        }

        .notice {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 15px;

            span {
                font-size: 14px;

                a {
                    font-family: "Suisse-Medium";
                    font-weight: normal;
                    color: #f9c23c;
                }

                & + span {
                    margin-top: 5px;
                }
            }

            br {
                font-family: "Suisse-Medium";
                font-weight: normal;
            }
        }
    }
}
