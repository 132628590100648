.navbar-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto;
    align-items: center;
    max-width: 1240px;
    overflow: hidden;
    padding: 16px;

    @media only screen and (min-width: 900px) {
        max-width: 95%;
        padding: 32px 10px 0px 10px;
    }

    @media only screen and (min-width: 1200px) {
        max-width: 1100px;
        padding: 32px 0px 0px 0px;
    }

    @media only screen and (min-width: 1400px) {
        max-width: 1240px;
    }

    button {
        background: none;
        border: none;
    }

    #links-web {
        display: none;
        flex-direction: row;
        margin: auto;
        padding: 0;

        @media only screen and (min-width: 900px) {
            display: flex;
        }

        li {
            a {
                color: #1d1d20;
                font-size: 14px;
                text-decoration: none;
                font-family: "Suisse-Medium";
                white-space: nowrap;
            }

            @media only screen and (max-width: 1200px) {
                &.hidden-md {
                    display: none;
                }
            }

            list-style: none;
            margin: 0 1rem;

            @media only screen and (min-width: 1200px) {
                margin: 0 1.5rem;
            }
        }
    }

    #auth-web {
        display: none;
        flex-direction: row;
        align-items: center;
        margin-left: auto;
        margin-top: 5px;
        margin-right: -1rem;

        @media only screen and (min-width: 900px) {
            display: flex;
        }

        @media only screen and (min-width: 1200px) {
            margin-right: -1.5rem;
        }

        button,
        a {
            color: #1d1d20;
            font-size: 14px;
            text-decoration: none;
            font-family: "Suisse-Medium";
            margin: 0 1rem;
            white-space: nowrap;
            cursor: pointer;

            @media only screen and (min-width: 1200px) {
                margin: 0 1.5rem;
            }

            &#get-started {
                padding: 8px 12px;
                border-radius: 16px;
                box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
                border: solid 1px #ededee;
            }
        }
    }


    .image-container {
        margin-right: auto;
        cursor: pointer;

        img {
            width: 185px;
            object-fit: contain;
        }
    }

    .toggle-icon {
        display: block;
        margin-left: auto;

        @media only screen and (min-width: 900px) {
            display: none;
        }
    }
}

.mobile-nav {
    position: absolute;
    display: none;
    flex-direction: column;
    touch-action: none;
    background: #202023;
    width: 100vw;
    height: 100svh;
    z-index: 999;
    padding: 16px;

    @media only screen and (max-width: 900px) {
        &.open {
            display: flex;
        }
    }

    button {
        background: none;
        border: none;
    }

    .mobile-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        img {
            width: 185px;
            object-fit: contain;
            margin-right: auto;
        }
    }

    .mobile-body {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        li {
            list-style: none;

            a {
                font-family: "Suisse-Medium";
                font-size: 32px;
                color: white;
                text-decoration: none;
            }

            &+li {
                margin-top: 1.5rem;
            }
        }

        hr {
            border-top: 1px solid white;
            width: 120px;
            margin-top: 30px;
            margin-bottom: 1.5rem;
        }
    }

    .mobile-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: Suisse-Regular;
        font-size: 12px;
        color: white;
        text-align: center;

        p,
        a {
            font-size: 12px;
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
}