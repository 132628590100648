.success-modal {
    display: flex;
    flex-direction: column;
    max-width: 450px;

    .modal-subtitle {
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
    }

    .modal-body {
        text-align: center;

        img {
            object-fit: contain;
            height: 220px;
        }

        p {
            font-size: 15px;
        }

        #submit-form {
            font-family: "Suisse-Medium";
            margin-top: 30px;
        }
    }

    .modal-footer {
        text-align: center;
    }
}