.mobile-card {

    h1 {
        font-family: "Suisse-Medium";
        font-weight: normal;
        font-size: 72px;
    }

    a {
        font-family: "Suisse-Medium";
        font-weight: normal;
        color: #F9C23C;
    }
}