.analytics-container {
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 2200px;

    .analytics-scroll-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        overflow-x: auto;

        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        @media only screen and (max-width: 999px){
            padding: 0 50px;
            
            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 0;
                width: 60px;
                height: 100%;
            }
        
            &::before {
                background: linear-gradient(to right, white, transparent);
                left: 0;
            }
        
            &::after {
                background: linear-gradient(to right, transparent, white);
                right: 0;
            }
        }

        @media only screen and (min-width: 1000px) {
            padding: 0;
            width: 85%;
            justify-content: space-around;
        }
    }

    .analytics-item-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media only screen and (max-width: 999px) {
            min-width: 310px;
        }

        .analytics-data-container {
            display: flex;
            flex-direction: column;
            margin-left: 25px;

            .description {
                font-size: 16px;
            }

            .counter {
                font-size: 38px;
                font-family: "Suisse-Medium";
                font-weight: normal;
            }
        }
    }
}