.isp-page-container {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start;
    width: 100%;
    max-width: 1550px;
    padding-bottom: 20px !important;
    gap: 20px;

    @media only screen and (max-width: 991px) {
        padding-bottom: 120px !important;
    }

    @media only screen and (min-width: 1450px) {
        flex-wrap: wrap;
        flex-direction: row !important;
    }

    .left-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 20px;
        flex-grow: 1;
        width: 100%;

        @media only screen and (min-width: 1450px) {
            flex: 1 1 calc(65% - 10px);
        }

        .data-container {
            flex: 1 1 50%;

            .card {
                flex: 0 0 50%;
                width: 100%;
                margin-top: 16px;
            }

            .order-selector-card {
                &+.subtitle-container {
                    margin-top: 32px;
                }
            }
        }

        .result-container {
            flex: 1 1 50%;
            height: 100%;
            padding-bottom: 20px;

            @media only screen and (min-width: 992px) {
                padding-bottom: 0px;
                padding-top: 35px;
            }

            .result-card {
                height: 350px;

                @media only screen and (min-width: 992px) {
                    height: 515px;
                }
            }
        }
    }

    .purchase-container {
        width: 100%;

        @media only screen and (min-width: 1450px) {
            flex: 1 1 calc(35% - 10px);
        }

        .proxy-card {
            width: 100%;
            margin-top: 16px;

            .details-row {
                svg {
                    min-width: 16px;
                    min-height: 16px;
                }

                overflow: hidden;

                span {
                    text-align: start;
                    text-wrap: nowrap;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
    }
}