.product-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    padding-top: 30px;

    @media only screen and (min-width: 1400px) {
        padding-top: 140px;
        padding-bottom: 10px;
    }

    .product-details-container {
        display: flex;
        flex-direction: column;
        max-width: 90%;
        gap: 50px;
        z-index: 2;

        @media only screen and (min-width: 1400px) {
            max-width: 1400px;
            flex-direction: row;
        }
    }

    .return-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: 'Inter-Medium';
        background: white;
        border: 2px solid #D8D9DF;
        padding: 0.5rem 1.75rem;
        border-radius: 6px;
        font-size: 15px;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
        margin-top: 70px;
        text-decoration: none;
        color: #000000BF;

        img {
            margin-right: 10px;
        }
    }
}