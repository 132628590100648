.custom-slider {
    margin: auto;
}

.custom-slider-track {
    top: 6px;
    height: 6px;
    background: #F7F7F8;
}

.custom-slider-thumb {
    cursor: pointer;
    background: #F7F7F8;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    outline: none;
    border: 6px solid #1D1D20;
}