.order-modal {
    display: flex;
    flex-direction: column;
    max-width: 600px;

    .card-title {
        margin-right: 15px;
    }

    .modal-body {
        max-height: 450px;
        overflow-y: auto;

        .card-order {
            width: 100%;
        }

        .order-details-card {
            &+.order-details-card {
                margin-top: 10px;
            }
        }
    }
}