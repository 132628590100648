$primary: #F9C23C;

.server-card {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #ededee;
    background-color: #fff;
    flex: 31% 0 0;

    @media only screen and (min-width: 1400px) {
        max-height: 570px;
    }

    .server-card-header {
        padding: 32px 24px 0px 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .server-card-title-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .server-card-title {
                font-size: 36px;
                font-family: "Suisse-Regular";
                font-weight: normal;
                margin-left: 16px;

                @media only screen and (min-width: 1400px) {
                    font-size: 36px;
                }
            }

            .product-selector {
                margin-left: auto;
                display: flex;
                flex-direction: column;
                padding: 4px;
                height: fit-content;
                margin-left: auto;
    
                button {
                    padding: 5px 12px;
                    font-size: 12px;
                    line-height: unset;
                    border: solid 1px transparent;
                    height: fit-content;
                }

                @media only screen and (min-width: 600px) {
                    flex-direction: row;
                }
            }

            .icon-container {
                min-width: 40px;
                max-width: 40px;
                min-height: 40px;
                max-height: 40px;
                padding: 6px;
                border-radius: 4px;
                background-color: $primary;
            }
        }
    }

    .server-card-body {
        padding: 32px 24px 0px 24px;
        display: flex;
        flex-direction: column;
        padding-top: 17px;

        .server-card-description {
            font-size: 14px;
            font-family: "Suisse-Regular";
            font-weight: normal;
            line-height: 1.71;
        }

        .price-container {
            display: flex;
            flex-direction: row;
            margin-top: 20px;

            .price-total {
                font-family: "Suisse-Medium";
                font-weight: normal;
                font-size: 34px;
                color: #1d1d20;
                line-height: 1;
            }

            .price-detail {
                margin-left: 10px;
                align-self: flex-end;
                font-family: "Suisse-Regular";
                font-size: 14px;
                line-height: 1.43;
                color: #4f4f51;
            }
        }

        .specs-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 16px;
            margin-bottom: 16px;

            @media only screen and (min-width: 1400px) {
                margin-top: 28px;
                margin-bottom: 4px;
            }

            .chip {
                padding: 4px 12px;
                border-radius: 4px;
                background-color: #ededee;

                &+.chip {
                    margin-left: 12px;
                }
            }
        }

        .details-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 12px;
            margin-bottom: 35px;

            .details-row {
                flex: 50% 0 0;
                margin-top: 18px;
                display: flex;
                align-items: center;

                img {
                    width: 16px;
                    height: 16px;
                }

                span {
                    margin-left: 8px;
                }
            }

        }
    }

    button {
        font-family: "Suisse-Medium";
        font-weight: normal;
        font-size: 14px;
        color: #1d1d20;
        cursor: pointer;
    }

    #buy-now {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        border-radius: 4px;
        border: solid 1px #ecb839;
        background-color: #f9c23c;

        &:disabled {
            opacity: 50%;
        }

        &.oos {
            opacity: 100%;
            cursor: unset;
            border: solid 1px #f0f0f0;
            background-color: #f0f0f0;
        }

        @media only screen and (min-width: 1400px) {
            margin-top: 30px;
        }
    }

    .show-details {
        margin-top: 12px;
        padding: 10px 0;
        border-radius: 8px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px #ededee;
        background-color: #fff;
    }

    .server-card-footer {
        padding: 24px;
        margin-top: 20px;
        background: #2C2C2C08;
        border-radius: 8px;
        border: 1px solid #2C2C2C12;
        flex-grow: 1;

        .details-title {
            font-family: 'Suisse-Medium';
            font-weight: 500;
            font-size: 15px;
        }

        .details-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 20px;
            gap: 18px;

            .details-row {
                flex: 1 0 45%;
                display: flex;
                align-items: center;

                img {
                    width: 16px;
                    height: 16px;
                }

                span {
                    margin-left: 8px;
                }
            }

            .details-row {
                overflow: hidden;

                span {
                    text-align: start;
                    text-wrap: nowrap;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }

        }
    }
}