.review-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.858);
    border-radius: 8px;
    border: solid 1px #ededee;
    padding: 24px;
    margin: 0 5px;
    height: 100%;
    z-index: 2;

    @media only screen and (min-width: 900px) {
        width: 50%;
        padding: 48px;
        margin: 0;
        height: auto;

        &.extra {
            background-image: linear-gradient(225deg, #f8b30c, rgba(255, 230, 0, 0));
    
            .review {
                font-size: 28px;   
                line-height: 1.22;

                @media only screen and (min-width: 1200px) {
                    font-size: 36px;   
                }
            }
        }
    }

    img {
        width: auto !important;
        z-index: 2;
    }

    .review {
        margin-top: 25px;
        font-size: 18px;   
        text-align: left;
        line-height: 1.42;

        @media only screen and (min-width: 900px) {
            margin-top: 35px;
            font-size: 22px;   
        }

        @media only screen and (min-width: 1200px) {
            font-size: 24px;   
        }
    }

    .reviewer {
        margin-top: 36px;
        font-size: 16px;

        @media only screen and (min-width: 900px) {
            font-size: 18px;   
        }
    }

    .position {
        margin-top: 12px;
        color: #7D7E89;
    }
}