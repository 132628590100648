.blog-admin-page-container {

    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 100%;

    @media only screen and (max-width: 991px) {
        padding-bottom: 120px !important;
    }

    .left-container {
        max-width: 100%;

        .add-container {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            width: 100%;

            .add-card {
                width: 100%;
                max-width: 800px;
                height: 650px;
                overflow: auto;

                [id*=editor-js] {
                    width: 100%;
                    z-index: 5;
                }
            }
        }

        .save-container {
            margin-top: 20px;
            width: 800px;
            max-width: 100%;

            .card {
                padding: 10px 20px;
                display: flex;
                flex-direction: row;
                width: 100%;
                height: unset;
                min-height: 60px;
                align-items: center;
                flex-wrap: wrap;
                row-gap: 10px;

                input {
                    width: 220px;
                    margin-top: unset;
                    margin: 0 10px;
                }

                .align-right {
                    margin-left: auto;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                button {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    background: none;
                    border-radius: 5px;
                    border: 1px solid grey;
                    padding: 10px 15px;
                    height: 35px;
                    font-family: "Suisse-Medium";

                    &.selected {
                        background-color: rgb(186, 233, 117);
                    }

                    &+button {
                        margin-left: 10px;
                    }

                    &.delete {
                        border: none;
                        background: rgb(250, 72, 72);
                        color: white;
                    }
                }
            }
        }
    }

    .right-container {

        .posts-container {
            display: flex;
            flex-direction: column;
            max-height: 735px;
            overflow: auto;
            overflow-x: hidden;
            margin-top: 20px;
            gap: 20px;

            .post-card {
                width: 300px;
                height: unset;
                display: flex;
                flex-direction: column;
                padding: 15px;
                cursor: pointer;

                .top-row {
                    display: flex;
                    flex-direction: row;

                    .flex-column {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        
                        span {

                            &+span {
                                margin-top: 5px;
                            }
                        }

                        .creator {
                            font-family: "Suisse-Medium";
                        }
                    }

                    img {
                        width: 110px;
                        max-height: 80px;
                        object-fit: contain;
                        border-radius: 5px;
                    }
                }

                .title {
                    margin-top: 20px;
                    font-size: 16px;
                    max-lines: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: darkgrey;
                }
            }
        }

    }
}