.popup-modal-overlay:has(.popup-modal):has(.proxy-card-modal){
    background-color: #F7F7F899;
    backdrop-filter: blur(1px);
}

.proxy-card.proxy-card-modal{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    border: none;
    overflow: hidden;

    .proxy-card-body{
        margin-bottom: auto;
        hr{
            border-color: #ededee;
            border: none;
            background-color: #ededee;
            height: 1px;
            width: 100%;
            margin: 0;
        }

        .proxy-card-description, hr{
            margin-bottom: 10px;
        }
    }

    .proxy-card-modal-body{
        &-button{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 8px;
            width: 100%;
            text-align: center;
            border: solid 1px #ecb839;
            background-color: #f9c23c;
            border-radius: 4px;
            padding: 10px 0;
            font-family: "Suisse-Bold";
            margin-top: 5px;
        }

        &-error{
            color: red;
            font-size: 12px;
            margin-top: 5px;
        }
    }

    .proxy-card-footer{
        margin-top: 15px;
        flex-grow: 0;
    }
}